<template>
  <app-layout class="bg-background">
    <div class="container mx-auto">
      <app-table
        :tabs="tabs"
        :apiResponse="list_business"
        :showEmptyButton="false"
        @pagination-per-page-selected="per_page_changed"
        @params-changed="params_changed"
        @tab-changed="tab_changed"
        :loading="loading"
        @on-item-click="show_detail"
        :filters="[
          {
            key: 'filter[name]',
            type: 'text',
            placeholder: $t('business.table.header_businss_name'),
          },
          {
            key: 'filter[merchant_type]',
            type: 'select',
            placeholder: $t('business.table.header_merchant_type'),
            items: merchant_types,
          },
          {
            key: 'filter[submitted_at_between]',
            type: 'date',
            value: '',
          },
          {
            key: 'filter[type_of_business_id]',
            type: 'select',
            placeholder: $t('business.table.header_businss_type'),
            items: typeOfBusinesses,
          },
          {
            key: 'filter[detail.industry_id]',
            type: 'select',
            placeholder: $t('business.table.header_industry'),
            items: industries,
          },
          {
            key: 'filter[country_id]',
            type: 'select',
            placeholder: $t('business.table.header_country'),
            items: countries,
          },
        ]"
      >
        <template v-slot:header>
          <td>
            <b>{{ $t("business.table.header_businss_name") }}</b>
          </td>
          <td>
            <b>{{ $t("business.table.header_merchant_type") }}</b>
          </td>
          <td>
            <b>{{ $t("business.table.header_businss_type") }}</b>
          </td>
          <td>
            <b>{{ $t("business.table.header_industry") }}</b>
          </td>
          <td>
            <b>{{ $t("business.table.header_country") }}</b>
          </td>
          <td>
            <b>{{ $t("business.table.header_submitted_date") }}</b>
          </td>
          <td>
            <b>{{ $t("business.table.header_status") }}</b>
          </td>
        </template>
        <template v-slot:body="data">
          <td>
            {{ data.model?.name }}
          </td>
          <td class="capitalize">
            <p v-html="getMasterMerchant(data.model).split('_').join(' ')" />
          </td>
          <td>
            <p>{{ data.model?.type_of_business?.description ?? "-" }}</p>
          </td>
          <td>
            {{ data.model?.detail?.industry?.name ?? "-" }}
          </td>
          <td>
            <p>{{ data.model?.country?.name ?? "-" }}</p>
          </td>
          <td>
            <p>
              {{
                data.model?.submitted_at
                  ? $moment(data.model.submitted_at).format("DD MMMM YYYY")
                  : "-"
              }}
            </p>
          </td>
          <td>
            <app-badge :status="getStatus(data.model?.business_status_id)">
              {{ data.model?.status?.name ?? "-" }}
            </app-badge>
          </td>
        </template>
      </app-table>
    </div>
  </app-layout>
</template>

<script>
import BUSINESS_STATUS from "@/utils/const/business_status";
import MERCHANT_TYPE from "@/utils/const/merchant_type";

export default {
  data() {
    return {
      MERCHANT_TYPE: MERCHANT_TYPE,
      tabs: [],
      current_tab: null,
      filters: {},
    };
  },
  created() {
    this.fetchListBusiness();
  },
  computed: {
    list_business() {
      return this.$store.getters["businessStore/list_business"];
    },
    merchant_types() {
      return this.$store.getters["businessStore/merchant_types"];
    },
    loading() {
      return this.$store.getters["businessStore/loading"];
    },
    typeOfBusinesses() {
      return this.$store.getters["referenceStore/typeOfBusinesses"];
    },
    industries() {
      return this.$store.getters["referenceStore/industries"];
    },
    countries() {
      return this.$store.getters["referenceStore/countries"];
    },
  },
  mounted() {
    this.fetchListBusiness();

    this.$store.dispatch("referenceStore/getTypeOfBusinesses", 0);
    this.$store.dispatch("referenceStore/getIndustries", 0);
    this.$store.dispatch("referenceStore/getCountries", 0);
    this.$store.dispatch("businessStore/getAllMerchantType");

    this.tabs = [
      { key: "all", title: this.$t("business.table.tab_all") },
      { key: "review", title: this.$t("business.table.tab_to_review") },
      { key: "approved", title: this.$t("business.table.tab_approved") },
      { key: "amended", title: this.$t("business.table.tab_amended") },
      { key: "rejected", title: this.$t("business.table.tab_rejected") },
    ];
  },
  methods: {
    getStatus(status_id) {
      switch (status_id) {
        case 1:
          return "light";
        case 2:
          return "light";
        case 3:
          return "success";
        case 4:
          return "error";
        case 5:
          return "warning";
        default:
          return "light";
      }
    },
    show_detail(business) {
      this.$router.push({
        name: "business-detail",
        params: { business_id: business.id },
      });
    },
    params_changed(filters, queryParams) {
      this.filters = filters;
      this.filterFormatDate(filters["filter[submitted_at_between]"]);
      this.filters["filter[type_of_business_id]"] =
        filters["filter[type_of_business_id]"]?.id;
      this.filters["filter[detail.industry_id]"] =
        filters["filter[detail.industry_id]"]?.id;
      this.filters["filter[country_id]"] = filters["filter[country_id]"]?.id;
      this.filters["filter[merchant_type]"] =
        filters["filter[merchant_type]"]?.id;
      this.fetchListBusiness();
    },
    per_page_changed(per_page) {
      this.filters.per_page = per_page;
      this.fetchListBusiness();
    },
    tab_changed(tab_name) {
      this.current_tab = tab_name;
    },
    fetchListBusiness() {
      this.getTabStatusTab();
      this.$store.dispatch("businessStore/getListBusiness", this.filters);
    },
    filterFormatDate(date) {
      let start = date?.start_date;
      let end = date?.end_date;

      if (date)
        this.filters["filter[submitted_at_between]"] = start + "," + end;
    },
    getTabStatusTab() {
      var status_tab = null;
      let currentTab = this.current_tab ?? this.$route.query.tab;

      if (currentTab == "review") {
        status_tab = BUSINESS_STATUS.SUBMITTED;
      } else if (currentTab == "approved") {
        status_tab = BUSINESS_STATUS.APPROVED;
      } else if (currentTab == "amended") {
        status_tab = BUSINESS_STATUS.AMENDED;
      } else if (currentTab == "rejected") {
        status_tab = BUSINESS_STATUS.REJECTED;
      } else {
        status_tab = [
          BUSINESS_STATUS.APPROVED,
          BUSINESS_STATUS.SUBMITTED,
          BUSINESS_STATUS.AMENDED,
          BUSINESS_STATUS.REJECTED,
        ].join(",");
      }

      this.filters["filter[business_status_id]"] = status_tab;
    },

    getMasterMerchant(masterMerchant) {
      if (masterMerchant.master_business_type == null) {
        return MERCHANT_TYPE.MERCHANT;
      } else if (masterMerchant.master_business_type == "master_merchant") {
        return MERCHANT_TYPE.MASTER_MERCHANT;
      } else if (masterMerchant.master_business_type == "sub_merchant") {
        if (masterMerchant.master_business_id != null)
          var link = this.$router.resolve({ name: "business-detail", params: { business_id: masterMerchant.master_business.id } }).href;
        return (
          MERCHANT_TYPE.SUB_MERCHANT + " ( <a href='" + link + "'>" + masterMerchant.master_business.name + "</a> )"
        );
      }
      return MERCHANT_TYPE.SUB_MERCHANT;
    },
  },
};
</script>
